var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"splash-product",staticClass:"splash-product"},[_c('div',{staticClass:"splash-product__info",class:{
      'splash-product--left': _vm.index % 2 === 0,
      'splash-product--right': _vm.index % 2 !== 0
    }},[_c('h2',[_vm._v(_vm._s(_vm.get_correct_translation_value(_vm.product, "title", [_vm.selected_language])))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.product_message)}}),_c('div',{staticClass:"splash-product__images splash-product__images--top",class:{
        'splash-product--left': _vm.index % 2 !== 0,
        'splash-product--right': _vm.index % 2 === 0
      }},[(_vm.rest_config.splash_image_color)?_c('v-lazy-image',{staticClass:"splash-product__background load-animation-class",attrs:{"src":`/static/backgrounds/color-splash-round-${_vm.rest_config.splash_image_color}.png`,"alt":"Splash color image"}}):_vm._e(),_c('router-link',{attrs:{"to":_vm.product_link,"itemprop":"link"}},[_c('v-lazy-image',{staticClass:"splash-product__product-img",class:{
            'load-animation-class': !_vm.is_safari,
            'splash-product__product-img--larger': !_vm.rest_config.splash_image_color
          },style:({ animation: _vm.is_safari ? 'none' : 'float_item 6s ease-in-out infinite'}),attrs:{"src":_vm.img_to_be_shown,"alt":"Main product image"}})],1)],1),_c('Checkout',{staticClass:"right-panel",attrs:{"product_data":_vm.product,"index":_vm.index,"add_description":false,"use_link":_vm.product_link,"show_open_product_button":true,"show_title":false},on:{"user_changed_variation":_vm.switch_image}})],1),_c('div',{staticClass:"splash-product__images splash-product__images--bottom",class:{
      'splash-product--left': _vm.index % 2 !== 0,
      'splash-product--right': _vm.index % 2 === 0
    }},[(_vm.rest_config.splash_image_color)?_c('v-lazy-image',{staticClass:"splash-product__background load-animation-class",attrs:{"src":`/static/backgrounds/color-splash-round-${_vm.rest_config.splash_image_color}.png`,"alt":"Splash color image"}}):_vm._e(),_c('router-link',{attrs:{"to":_vm.product_link,"itemprop":"link"}},[_c('v-lazy-image',{staticClass:"splash-product__product-img",class:{
          'load-animation-class': !_vm.is_safari,
          'splash-product__product-img--larger': !_vm.rest_config.splash_image_color
        },style:({ animation: _vm.is_safari ? 'none' : 'float_item 6s ease-in-out infinite'}),attrs:{"src":_vm.img_to_be_shown,"alt":"Main product image"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }