<template>
  <div
    class="splash-product"
    ref="splash-product"
  >
    <div
      class="splash-product__info"
      :class="{
        'splash-product--left': index % 2 === 0,
        'splash-product--right': index % 2 !== 0
      }"
    >
      <h2>{{ get_correct_translation_value(product, "title", [selected_language]) }}</h2>
      <p v-html="product_message" />
      <div
        class="splash-product__images splash-product__images--top"
        :class="{
          'splash-product--left': index % 2 !== 0,
          'splash-product--right': index % 2 === 0
        }"
      >
        <v-lazy-image
          v-if="rest_config.splash_image_color"
          :src="`/static/backgrounds/color-splash-round-${rest_config.splash_image_color}.png`"
          alt="Splash color image"
          class="splash-product__background load-animation-class"
        />
        <router-link :to="product_link" itemprop="link">
          <v-lazy-image
            :src="img_to_be_shown"
            alt="Main product image"
            class="splash-product__product-img"
            :class="{
              'load-animation-class': !is_safari,
              'splash-product__product-img--larger': !rest_config.splash_image_color
            }"
            :style="{ animation: is_safari ? 'none' : 'float_item 6s ease-in-out infinite'}"
          />
        </router-link>
      </div>
      <Checkout
        :product_data="product"
        :index="index"
        :add_description="false"
        :use_link="product_link"
        :show_open_product_button="true"
        :show_title="false"
        class="right-panel"
        @user_changed_variation="switch_image"
      />
    </div>
    <div
      class="splash-product__images splash-product__images--bottom"
      :class="{
        'splash-product--left': index % 2 !== 0,
        'splash-product--right': index % 2 === 0
      }"
    >
      <v-lazy-image
        v-if="rest_config.splash_image_color"
        :src="`/static/backgrounds/color-splash-round-${rest_config.splash_image_color}.png`"
        alt="Splash color image"
        class="splash-product__background load-animation-class"
      />
      <router-link :to="product_link" itemprop="link">
        <v-lazy-image
          :src="img_to_be_shown"
          alt="Main product image"
          class="splash-product__product-img"
          :class="{
            'load-animation-class': !is_safari,
            'splash-product__product-img--larger': !rest_config.splash_image_color
          }"
          :style="{ animation: is_safari ? 'none' : 'float_item 6s ease-in-out infinite'}"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import find_image_by_id from "../../../methods/find_image_by_id"
import create_correct_product_url from "../../../methods/create_correct_product_url"
import get_message_or_types_from_product_description from "../../../../Shared/methods/get_message_or_types_from_product_description"
import { image_dimension_names } from "../../../../../data/other_constants"
import { CLIENT_STORE } from "../../../constants/other"
import { SHARED_STORE } from "../../../../Shared/constants/other"
import get_correct_first_image from "../../../../Shared/methods/get_correct_first_image"
import get_image_src from "../../../../Shared/methods/get_image_src"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"

const Checkout = () => import("../../../../Shared/components/utils/checkout")

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  components: {
    Checkout,
    VLazyImage
  },
  data() {
    return {
      img_to_be_shown: this.decide_initial_image_of_product()
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_resolution",
    ]),
    ...mapState(CLIENT_STORE, [
      "is_mobile",
      "user_is_using_fast_connection",
      "is_safari",
      "translations",
      "rest_config",
      "selected_language"
    ]),
    product_link() {
      return create_correct_product_url(this.product)
    },
    product_message() {
      return get_message_or_types_from_product_description(this.product.body_html, true)
    },
    splash_image_color() {
      return this.product.splash_image_version ?
        this.product.splash_image_version : "red"
    }
  },
  methods: {
    get_correct_translation_value,
    switch_image({ product_id, image_id }) {
      if(product_id === this.product.id) {
        const image = find_image_by_id(this.product.images, image_id)

        this.img_to_be_shown = get_image_src(
          image, this.is_mobile_resolution ? image_dimension_names.tablet : image_dimension_names.desktop
        )
      }
    },
    decide_initial_image_of_product() {
      return get_correct_first_image(this.product)
    },
  }
}
</script>

<style lang="scss">
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/load_animation.scss";
@use "../../../../../styles/float_animation.scss";

$margin_and_height: 300px;

.splash-product {
  position: relative;
  display: grid;
  grid-template-areas: "left right";
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  margin: 0 auto $margin_and_height;

  @media (max-width: $tablet) {
    display: block;
  }

  &--left {
    grid-area: left;
    text-align: right;

    .checkout__wrapper {
      text-align: right;
    }
  }

  &--right {
    grid-area: right;
  }

  &__info {
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  &__background {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
    z-index: 1;
  }

  &__images {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    @media (max-width: $tablet) {
      width: 100%;
      height: 400px;
    }

    &--top {
      @media (min-width: $tablet) {
        display: none;
      }
    }

    &--bottom {
      @media (max-width: $tablet) {
        display: none;
      }
    }
  }

  &__product-img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 50%;
    max-height: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 0 15px $pure-black);

    &--larger {
      width: 100%;
      height: 100%;
      object-fit: contain;
      min-width: 400px;
      min-height: 400px;
    }

    @media (max-width: $tablet) {
      height: 200px;
    }

    @media (max-width: 500px) {
      max-width: 90%;
      max-height: none;
    }
  }
}
</style>
