<template>
  <section v-if="!$route.params.type && !$route.params.category" class="couple-product-homepage">
    <h1 class="couple-product-homepage__title text-title text-title--main" ref="title">
      {{ get_correct_translations_data.title }}
    </h1>
    <p
      v-if="get_correct_translations_data.message_top"
      class="
        couple-product-homepage__paragraph
        single-couple-styles__paragraph
        single-couple-styles__paragraph--center
        load-animation-class
      "
    >
      {{ get_correct_translations_data.message_top }}
    </p>
    <section>
      <div
        v-for="(item, index) in array_of_products"
        :key="`splash-products-${index}`"
        class="couple-product-homepage__product"
      >
        <p
          v-if="item.message"
          class="
            single-couple-styles__paragraph
            single-couple-styles__paragraph--margin-no-side
            couple-product-homepage__middle-message
            load-animation-class
          "
          :class="`single-couple-styles__paragraph--${index % 2 === 0 ? 'right' : 'left'}`"
        >
          {{ item.message }}
        </p>
        <splash_product_display
          v-else
          :product="{ ...item, collections: [get_collection] }"
          :index="index"
          :is_last_el="index === array_of_products.length - 1"
          :ref="`splash-products-${index}`"
        />
      </div>
    </section>
    <p
      v-if="get_correct_translations_data.message_top"
      class="
        single-couple-styles__paragraph
        single-couple-styles__paragraph--center
        single-couple-styles__paragraph--margin
        couple-product-homepage__paragraph
        load-animation-class
      "
    >
      {{ get_correct_translations_data.message_bottom }}
    </p>
    <div v-if="rest_config.brand_banner" class="couple-product-homepage__logo-wrapper load-animation-class">
      <a
        :href="create_main_page_url"
        class="link"
        itemprop="link"
        target="_blank"
      >
        <v-lazy-image
          :src="get_banner_image"
          :src-placeholder="default_image_src"
          alt="Brand banner"
        />
      </a>
      <p class="couple-product-homepage__logo-message">
        {{ translations.main_page_link_message }}
      </p>
      <a
        :href="create_main_page_url"
        class="link"
        itemprop="link"
        target="_blank"
      >
        <p class="couple-product-homepage__button">
          {{ translations.main_page_link_button }}
        </p>
      </a>
    </div>
  </section>
  <router-view v-else-if="$route.params.type !== home"/>
  <div v-else class="webpage__loading">
    <Loader class="webpage__loading-content" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2"
import Loader from "@/applications/Shared/components/Loader/index.vue"
import html_enity_encoder from "urlencode"
import splash_product_display from "../utils/couple_product_page/splash_product_display"
import default_page_type_translations from "../../../Admin/constants/empty_objects/default_page_type_translations"
import { default_image_src, SHARED_STORE } from "../../../Shared/constants/other"
import { CLIENT_STORE } from "../../constants/other"
import { ADD_PAGE_TYPE_TRANSLATIONS, FETCH_PRODUCTS_BY_IDS } from "../../stores/Client/constants"
import { fetch_page_type_data } from "../../constants/endpoints"
import { home } from "../../../../data/other_constants"

export default {
  components: {
    splash_product_display,
    Loader,
    VLazyImage
  },
  data() {
    return {
      data_loaded: false,
      default_image_src,
      home
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "products",
      "couple_products_homepage_translations",
      "selected_entity",
      "selected_language",
      "collections",
      "translations",
      "rest_config",
      "user_is_using_fast_connection",
    ]),
    ...mapState(SHARED_STORE, [
      "is_mobile_resolution",
      "store_id"
    ]),
    get_banner_image() {
      const thumb_image = this.rest_config.brand_banner?.thumb_url
      const image = this.rest_config.brand_banner?.image_url

      return (this.is_mobile_resolution || !this.user_is_using_fast_connection) ? thumb_image : image ||
        this.rest_config.brand_banner ||
        default_image_src
    },
    get_correct_translations_data() {
      return this.couple_products_homepage_translations[this.selected_language] ||
        default_page_type_translations["couple_products_homepages"]
    },
    /**
     * 
     */
    get_collection() {
      return (this.collections.find(({ handle }) => handle === html_enity_encoder(this.$route.query.collection)) || {})
    },
    /**
     * 
     */
    number_of_products() {
      return Object.values(this.get_collection.products).length
    },
    /**
     * 
     */
    splash_image_color() {
      return this.get_collection.splash_image_version ?
        this.get_collection.splash_image_version : "red"
    },
    /**
     * 
     */
    array_of_products() {
      if (!this.get_collection.products) return []

      const product_ids = this.get_collection.products.map(({ id }) => id)
      const products = this.products.filter(({ id }) => product_ids.includes(id))
      const index_of_middle = Math.floor(products.length / 2)

      if (this.get_correct_translations_data.message_middle) products.splice(
        index_of_middle,
        0,
        { message: this.get_correct_translations_data.message_middle }
      )

      return products
    },
    /**
     * 
     */
    create_main_page_url() {
      return `${window.location.origin}/${this.store_id}`
    }
  },
  async mounted() {
    const correct_collection = this.collections.find(({ handle }) => this.selected_entity === handle)
    await this.fetch_products({ ids: correct_collection?.products.map(({ id }) => id) || [] })
    const { data } = await fetch_page_type_data({ page_id: this.selected_entity, page_type: "couple" })
    this.update_translations([data, "couple"])

    this.data_loaded = true
  },
  methods: {
    ...mapActions(CLIENT_STORE, {
      fetch_products: FETCH_PRODUCTS_BY_IDS
    }),
    ...mapMutations(CLIENT_STORE, {
      update_translations: ADD_PAGE_TYPE_TRANSLATIONS
    }),
  }
}
</script>

<style lang="scss">
@use "../../../../styles/title.scss";
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/single_couple_page_styles.scss";

$margin_and_height: 300px; // As described in splash_product_display

.couple-product-homepage {
  position: relative;

  &__logo {
    &-wrapper {
      width: 50%;
      padding: 50px 0;
      margin: 330px auto 0;
      text-align: center;

      img {
        max-width: 100%;
      }

      .single-product-homepage__button {
        margin-bottom: 0;
      }

      @media (max-width: $tablet) {
        width: 70%;
        margin: 0 auto;
      }

      @media (max-width: $tablet--small) {
        width: 100%;
      }
    }

    &-message {
      margin-top: 40px;
    }
  }

  .single-couple-styles__paragraph {
    max-height: fit-content;
    overflow: visible;
  }

  &__button {
    display: block;
    width: 100%;
    max-width: 250px;
    margin: 50px auto 0;
    padding: 15px;
    color: $pure-white;
    font-size: 22px;
    text-align: center;
    background-color: $green-color;
    box-shadow: 0px 0px 20px 0px rgba($green-color, 0.7);
    transition: 250ms cubic-bezier(0.77, 0.65, 0.72, 1.04);
    border-radius: $border-radius;

    &:hover {
      box-shadow: none;
      transform: scale(0.99);
    }
  }

  &__title {
    position: relative;
    margin: 80px auto $margin_and_height !important;

    @media (max-width: $tablet) {
      border-right: none;
      border-left: none;
    }
  }

  &__paragraph {
    margin: 80px auto 150px !important;
  }

  &__product {
    &:last-child {
      margin-bottom: $margin_and_height + 40px;
    }
  }
  
  &__middle-message {
    position: relative;
    margin-bottom: 400px !important;

    &.single-couple-styles__paragraph--left {
      margin-left: auto;
    }
  }
}
</style>
